export const financingTypes = [
    { title: "Kauf einer Immobilie", value: "realEstate" },
    { title: "Bauvorhaben", value: "financing" },
    { title: "Umschuldung", value: "reFinancing" },
    //{ title: "Genossenschaftsbeitrag", value: "genossenschaft" },
    //{ title: "Umbau/Renovierung", value: "renovation" },
    { title: "Sonstiges", value: "miscellaneous" },
];

export const financingTypesMap = {
    realEstate: "purchase",
    financing: "construction",
    reFinancing: "refinancing",
    renovation: "other",
    miscellaneous: "other",
};

export const locations = [
    { title: "Wien", value: "Wien" },
    { title: "Niederösterreich", value: "Niederösterreich" },
    { title: "Oberösterreich", value: "Oberösterreich" },
    { title: "Salzburg", value: "Salzburg" },
    { title: "Tirol", value: "Tirol" },
    { title: "Vorarlberg", value: "Vorarlberg" },
    { title: "Burgenland", value: "Burgenland" },
    { title: "Steiermark", value: "Steiermark" },
    { title: "Kärnten", value: "Kärnten" },
    { title: "Sonstiger", value: "Sonstiger" },
];
export const runtime = [10, 15, 20, 25, 30, 35];

export const initialValues = {
    financingType: "",
    price: "",
    runtime: "",
    income: "",
    location: "",
};
